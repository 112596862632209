import React from "react";

export default function Cards({cards, wonders}) {
    const classes =  wonders ? " wonders" : "cards"

    return (<ul
            className={classes}
        >
            {cards.map((c, i) => <Card key={c.name + ":" + i} card={c} wonders={wonders}/>)}
        </ul>
    )
}

function Card({card, wonders}) {
    const classes = wonders ? " wonders" : "card"

    return (
        <li className={classes}
            key={card.name}>
            {card.name}
            {card.game === "fortunes" && <span style={{color:"darkred", fontWeight:500}}>: F</span>}
            {card.game === "villagers" && <span style={{color:"darkgreen",fontWeight:500}}>: V</span>}
            {card.game === "architects" && <span style={{color:"darkblue",fontWeight:500}}>: A</span>}
            {card.game === "aeg" && <span style={{color:"red",fontWeight:500}}>: AEG</span>}
            {card.game === "trees" && <span style={{color:"gold",fontWeight:500}}>: T</span>}
            {card.game === "printNplay" && <span style={{color:"lightseagreen",fontWeight:500}}>: PNP</span>}



        </li>
    )
}

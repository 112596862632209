import React, {useContext} from 'react'
import {Options} from "../OptionsContext";

export default function OptionsGroup() {
    let {fortunes, villagers, architects, aeg, treetop, printNplay, singlePlayer, playerCount} = useContext(Options)

    let playerMax = 6
    let playerMin = 1
    
    const toggleFortunes = () => {
        fortunes.set(!fortunes.value)
    }
    const toggleVillagers = () => {
        villagers.set(!villagers.value)
    }
    const toggleArchitects = () => {
        architects.set(!architects.value)
    }
    const toggleAeg = () => {
        aeg.set(!aeg.value)
    }
    const toggleTreetop = () => {
        treetop.set(!treetop.value)
    }
    const togglePrintNplay = () => {
        printNplay.set(!printNplay.value)
    }

    const toggleSinglePlayer = () => {
        singlePlayer.set(!singlePlayer.value)
    }

    const changePlayerCount = (e) => {
        let value = e.target.valueAsNumber
        if(value>playerMax){ value = playerMax }
        if(value<playerMin){ value = playerMin }
        console.log(value)
        playerCount.set(value)
    }

    return (<>
        <input type={"checkbox"} checked={fortunes.value} onChange={toggleFortunes}/>
        Fortunes
        <br/>
        <input type={"checkbox"} checked={villagers.value} onChange={toggleVillagers}/>
        Villagers
        <br/>
        {/* <input type={"checkbox"} checked={architects.value} onChange={toggleArchitects}/> */}
        {/* Architects */}
        {/* <br/> */}
        <input type={"checkbox"} checked={aeg.value} onChange={toggleAeg}/>
        AEG
        <br/>
        <input type={"checkbox"} checked={treetop.value} onChange={toggleTreetop}/>
        Trees
        <br/>
        <input type={"checkbox"} checked={printNplay.value} onChange={togglePrintNplay}/>
        Print and Play
        <br/>
        <br/>
        <input type={"checkbox"} checked={singlePlayer.value} onChange={toggleSinglePlayer}/>
        Single Player Rules
        <br/>
        <input type={"number"} value={playerCount.value} onChange={changePlayerCount} min={playerMin} max={playerMax}/>
        &nbsp;Player Count
        <br/>
    </>);
}

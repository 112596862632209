import React from 'react'

export default function AboutUs(props){

    return (<p>
        To help you start a new game of Tiny Towns with random cards.
        <br/><br/>
        Tiny Town <a href="https://boardgamegeek.com/boardgame/265736/tiny-towns">Board Game Geek page</a>
        <br/>
        Tiny Town is owned by AEG. <a href="https://www.alderac.com/tiny-towns/">Official webpage</a>

        
        <br/><br/>
        App Created By: Josh Schoonover
        <br/>
        All assets created by me with influence from AEG.
        <br/><br/>
        My <a href="https://home.schoonover.me">Homepage</a>
        <br/><br/>

    </p>)
}

import React, {useContext} from 'react'
import OptionsGroup from "./OptionsGroup";
import {pickCard} from "../logic/cardSort";
import Cards from "./Cards";
import {Options} from "../OptionsContext";
import './Wonders.css'

import wondersJSON from "../cards/wonders.json";

export default function Wonders(props) {
    let {fortunes, villagers, architects, aeg, treetop, printNplay, singlePlayer, playerCount} = useContext(Options)

    const [cards, setCards] = React.useState([])

    const handleDraw = () => {
        const temp = [];

        while (temp.length < (playerCount.value*2)) {
            let newCard = pickCard({fortunes, villagers, architects, aeg, treetop, printNplay, singlePlayer})(wondersJSON)

            if (temp.indexOf(newCard) === -1) {
                temp.push(newCard)
            }
        }
        setCards(temp)
    }

    // with expansions
    // have max of 4 from each
    //

    return (<div>
        <ol className='wonders'>
        {
            cards.map((value,index)=>{
                if(index%2===0){
                    //cards[index:index+1]
                    return <li><Cards cards={[cards[index], cards[index+1]]} wonders /></li>
                }else{
                    return 
                }
            })
        }
        </ol>
        {/* <Cards cards={cards} wonders/> */}
        
        <br/>

        <button onClick={handleDraw}>draw</button>
        <br/>

        <OptionsGroup/>
    </div>)
}

import React from "react";

export const Options = React.createContext(null);

export default function OptionsContext(props) {
    const [fortunes, setFortunes] = React.useState(false)
    const [villagers, setVillagers] = React.useState(false)
    const [architects, setArchitects] = React.useState(false)
    const [aeg, setAeg] = React.useState(false)
    const [treetop, setTreetop] = React.useState(false)
    const [printNplay, setPrintNplay] = React.useState(false)

    const [singlePlayer, setSinglePlayer] = React.useState(false)
    const [playerCount, setPlayerCount] = React.useState(2)
    
    const defaultOptions = {
        fortunes: {value: fortunes, set: setFortunes},
        villagers: {value: villagers, set: setVillagers},
        architects: {value: architects, set: setArchitects},
        aeg: {value:aeg, set: setAeg},
        treetop: {value: treetop, set: setTreetop},
        printNplay: {value: printNplay, set: setPrintNplay},

        singlePlayer: {value: singlePlayer, set: setSinglePlayer},
        playerCount: {value: playerCount, set: setPlayerCount},
    }

    return (
        <Options.Provider value={defaultOptions}>
            {props.children}
        </Options.Provider>
    )
}
